<template>
  <div>
    <b-card>
      <h2 class="mb-2">Período de aquisição</h2>
      <div class="row">
        <div class="d-flex col-md-6">
          <flat-pickr
            v-model="dataIncial"
            class="form-control bg-white"
            :config="dateFilterConfig"
            placeholder="Data inicial"
          />
          <flat-pickr
            v-model="dataFinal"
            class="form-control bg-white ml-1"
            :config="dateFilterConfig"
            placeholder="Data final"
          />
        </div>
        <div class="col-md-2">
          <button @click="baixarLastro" :disabled="dataIncial === '' || dataFinal === ''" class="btn btn-secondary">Baixar lastros</button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import http from '/src/services/http'
import { BCard } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'

export default {
  name: 'RelatorioSwap',
  components: {
    BCard,
    vSelect,
    flatPickr
  },
  data() {
    return {
      showModalEspera: false,
      errors: [],
      dateFilterConfig: {
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
      dataIncial: '',
      dataFinal: '',
    }
  },
  methods: {
    async baixarLastro() {
      this.showModalEspera = true
      this.showModalTermo = true
      await http.post(`/recebiveis/titulos/get_lastros`, {
        headers: { accept: 'application/json' },
        params: {
          data_inicial: this.dataIncial,
          data_final: this.dataFinal,
        },
      }).then(() => {
        this.$swal({
          title: 'Sucesso!',
          text: 'Estamos buscando os lastros dos títulos, enviaremos um email quando o processo for finalizado!',
          icon: 'success',
          button: 'Fechar',
        })
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.v-select {
  width: 260px;
  background: white;
  margin-bottom: 28px;
  margin-left: auto;
  margin-top: -55px;
}

.assignor-selector {
  min-width: 240px;
}

</style>
